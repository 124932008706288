@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    height: 100%;
    overflow: hidden;
  }
  body {
    @apply bg-[url('/public/images/cpu2.png')] xl:bg-[url('/public/images/cpu.png')] bg-no-repeat  bg-right xl:bg-left-bottom bg-fixed bg-background text-[#72767a] font-roboto antialiased overflow-y-scroll;
    touch-action: pan-y;
  }

  html {
    @apply text-[86%];
  }

  @media screen and (min-width: 640px) {
    html {
      @apply text-[90%];
    }
  }

  @media screen and (min-width: 768px) {
    html {
      @apply text-[94%];
    }
  }

  @media screen and (min-width: 1024px) {
    html {
      @apply text-[97%];
    }
  }

  @media screen and (min-width: 1280px) {
    html {
      @apply text-[100%];
    }
  }
}
